import { React, useState, useEffect } from 'react'
import '../index.css'

export default function Landing() {

    return (
        <div className="landing-grid" id ="landing">

            <div className="landing-grid-item">

            </div>

            <div className="landing-grid-item">
                <span className="landing-text-inverted text-5xl">
                    Upcoming
                </span>
                <span className="landing-text text-9xl">
                    Programme
                </span>
            </div>

            <div className="landing-grid-item">
                <p className="landing-text text-9xl">
                    Analyst Based
                </p>
            </div>

            <div className="landing-grid-item">
                <p className="landing-text text-9xl">
                    In Munich
                </p>
            </div>

            <div className="landing-grid-item">

            </div>

        </div>
    )
}